<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="primary--text subtitle-2 text-uppercase"
          >Muokkaa tehtävää</span
        >
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="validForm">
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Yksiköt"
                  v-model="units"
                  multiple
                  :items="availableUnits"
                  item-text="name"
                  item-value="id"
                  placeholder="Valitse yksiköt"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="code"
                  label="Tehtävä"
                  placeholder="esim. 704A"
                  outlined
                  required
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="municipality"
                  label="Kunta"
                  placeholder="esim. Kotka"
                  outlined
                  required
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="address"
                  label="Osoite"
                  placeholder="esim. Hätäkuja 112"
                  outlined
                  required
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="details"
                  label="Lisätiedot"
                  placeholder="esim. Ovikoodi 1234"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Peruuta
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="saving"
          :disabled="!validForm"
          @click="saveMission"
        >
          Tallenna
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="sass" scoped></style>
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean,
    active: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    validForm: false,
    saving: false
  }),
  computed: {
    dialog: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("closed", value);
      }
    },
    id: {
      get() {
        return this.$store.state.missions.editor.id;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.id", value]);
      }
    },
    time: {
      get() {
        return this.$store.state.missions.editor.time;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.time", value]);
      }
    },
    code: {
      get() {
        return this.$store.state.missions.editor.code;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.code", value]);
      }
    },
    municipality: {
      get() {
        return this.$store.state.missions.editor.municipality;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.municipality", value]);
      }
    },
    address: {
      get() {
        return this.$store.state.missions.editor.address;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.address", value]);
      }
    },
    details: {
      get() {
        return this.$store.state.missions.editor.details;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.details", value]);
      }
    },
    units: {
      get() {
        return this.$store.state.missions.editor.units;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.units", value]);
      }
    },
    availableUnits: {
      get() {
        return this.$store.getters["units/units"];
      }
    },
    original() {
      return this.$store.state.missions.missions[this.missionId];
    },
    hasChanges() {
      return this.$store.getters["missions/editorHasChanges"];
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async saveMission() {
      let me = this;
      try {
        me.saving = true;
        await me.$store.dispatch("missions/saveMissionInEditor");
        await me.$store.dispatch("missions/load");
        me.saving = false;
        me.dialog = false;
        me.$toast({
          type: "success",
          message: `Muutokset tehtävään '${me.code} - ${me.address}' on tallennettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.saving = false;
      }
    }
  }
};
</script>
