<template>
  <v-card class="elevation-5">
    <StatusEditor :active="editDialog" @closed="editDialog = false" />
    <v-data-table
      class="status-table"
      :items="status"
      :headers="headers"
      item-key="id"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="primary--text subtitle-2 text-uppercase"
            >Tilatiedot</v-toolbar-title
          >
          <v-spacer />
          <StatusCreator />
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <v-chip :text-color="item.colorText" :color="item.colorBackground">{{
          item.name
        }}</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editStatus(item.id)">
          mdi-pencil
        </v-icon>
        <v-icon
          :disabled="item.systemStatus"
          small
          @click="deleteStatus(item.id)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped></style>
<script>
// TODO: Chechk functionality and styling with other cases when we get those
import StatusCreator from "@/views/StatusCreator.vue";
import StatusEditor from "@/views/StatusEditor.vue";
export default {
  data() {
    return {
      isLoading: false,
      editDialog: false,
      headers: [
        { text: "Tila", value: "name", sortable: false },
        { text: "Numeerinen arvo", value: "value", sortable: false },
        { text: "", value: "actions", sortable: false, filterable: false }
      ]
    };
  },
  components: {
    StatusCreator,
    StatusEditor
  },
  computed: {
    status() {
      return this.$store.getters["status/status"];
    }
  },
  methods: {
    async editStatus(id) {
      let me = this;
      await me.$store.dispatch("status/loadStatusToEditor", id);
      me.editDialog = true;
    },
    async deleteStatus(id) {
      let me = this;
      try {
        let status = me.status.find(s => s.id === id);
        await me.$confirm(
          "Haluatko varmasti poistaa tilan " + status.name + " ?",
          "Vahvista poisto"
        );
        await me.$store.dispatch("status/deleteStatus", id);
        me.$toast({
          type: "success",
          message: `Tila ` + status.name + ` poistettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    }
  },
  async mounted() {}
};
</script>
