<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="primary--text subtitle-2 text-uppercase"
          >Muokkaa tilatietoa</span
        >
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="validForm">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="description"
                  :disabled="systemStatus"
                  label="Nimi"
                  filled
                  hint='Esimerkki: "Matkalla"'
                  required
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Numeerinen arvo"
                  hint='Käytä Virve-verkon status-arvoa, mikäli mahdollista. Esimerkki: "327001"'
                  v-model.number="value"
                  :disabled="systemStatus"
                  filled
                  required
                  :rules="$rules.number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                Taustaväri
                <v-color-picker
                  v-model="colorBackground"
                  flat
                  show-swatches
                  hide-canvas
                  hide-inputs
                  hide-mode-switch
                />
              </v-col>

              <v-col cols="6">
                Tekstin väri
                <v-color-picker
                  v-model="colorText"
                  flat
                  show-swatches
                  hide-canvas
                  hide-inputs
                  hide-mode-switch
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Peruuta
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="saving"
          :disabled="!hasChanges"
          @click="saveStatus"
        >
          Tallenna
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="sass" scoped></style>
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean,
    active: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    validForm: false,
    saving: false
  }),
  computed: {
    dialog: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("closed", value);
      }
    },
    id: {
      get() {
        return this.$store.state.status.editor.id;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.id", value]);
      }
    },
    value: {
      get() {
        return this.$store.state.status.editor.value;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.value", value]);
      }
    },
    description: {
      get() {
        return this.$store.state.status.editor.name;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.name", value]);
      }
    },
    systemStatus: {
      get() {
        return this.$store.state.status.editor.systemStatus;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.systemStatus", value]);
      }
    },
    colorText: {
      get() {
        return this.$store.state.status.editor.colorText;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.colorText", value]);
      }
    },
    colorBackground: {
      get() {
        return this.$store.state.status.editor.colorBackground;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.colorBackground", value]);
      }
    },
    original() {
      return this.$store.state.status.status[this.statusId];
    },
    hasChanges() {
      return this.$store.getters["status/editorHasChanges"];
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async saveStatus() {
      let me = this;
      try {
        me.saving = true;
        await me.$store.dispatch("status/saveStatusInEditor");
        await me.$store.dispatch("status/load");
        me.saving = false;
        me.dialog = false;
        me.$toast({
          type: "success",
          message: `Muutokset tilatietoon '${me.description}' on tallennettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.saving = false;
      }
    }
  }
};
</script>
