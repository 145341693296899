<template>
  <v-container class="main-container px-8">
    <v-row justify="center" align="center">
      <v-col cols="10">
        <NewAlertWidget :isLoading="isLoading" class="alert-widget" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass" scoped>
.alert-widget
  margin-top: 10px
  margin-bottom: 10px
</style>
<script>
// @ is an alias to /src
import NewAlertWidget from "@/components/NewAlertWidget.vue";

export default {
  name: "Alert",
  components: {
    NewAlertWidget
  },
  data: () => ({
    isLoading: false
  }),
  async mounted() {}
};
</script>
