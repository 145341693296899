<template>
  <v-container class="main-container px-8">
    <v-row justify="center" align="center">
      <v-col cols="10">
        <EventLogWidget :isLoading="isLoading" class="unit-list" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass" scoped>
.unit-list
  margin-top: 10px
  margin-bottom: 10px
</style>
<script>
// @ is an alias to /src
import EventLogWidget from "@/components/EventLogWidget.vue";

export default {
  name: "Events",
  components: {
    EventLogWidget
  },
  data: () => ({
    isLoading: false
  }),
  async mounted() {}
};
</script>
