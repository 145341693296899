<template>
  <VueDragResize
    :isActive="false"
    :isResizable="false"
    :w="280"
    :h="100"
    :x="leftFromStore"
    :y="topFromStore"
    v-on:dragstop="onDragStop"
    :parentLimitation="false"
    class="unit-moveable"
  >
    <v-card
      class="d-flex flex-column justify-space-between unit-card pa-1"
      :style="{ 'background-color': unitStatus.status.colorBackground }"
    >
      <div class="d-flex flex-column unit-name">
        <v-row no-gutters>
          <v-col>
            <span>{{ unitStatus.name }}</span>
          </v-col>
          <v-col md="auto"> </v-col>
        </v-row>
        <span class="unit-description text-uppercase">{{
          unitStatus.description
        }}</span>
      </div>
      <v-row no-gutters>
        <v-col class="status-age text-uppercase" style="align-self: flex-end">
          <FromNow :date="unitStatus.statusReceived" />
        </v-col>
        <v-col style="align-self: flex-end">
          <span class="status-name text-uppercase text-right">
            <v-select
              hide-details
              class="status-select"
              dense
              v-model="unitStatus.status"
              :items="statuses"
              item-text="name"
              item-value="id"
              return-object
              @change="updateStatus(unitStatus)"
            ></v-select>
          </span>
        </v-col>
      </v-row>
    </v-card>
  </VueDragResize>
</template>
<style lang="sass">
.unit-moveable
  width: 250px
  border-color: red
.status-select
  font-size: 12px
  color: red
.status-select.v-text-field>.v-input__control>.v-input__slot:before
  border-style: none
.status-select.v-text-field>.v-input__control>.v-input__slot:after
  border-style: none
.v-application--is-ltr .v-text-field .v-input__append-inner
  padding-left: 0px
.v-select.v-input--dense .v-select__selection--comma
  margin-bottom: 0px
.status-select .v-select__selections
  justify-content: flex-end
.mdi-menu-down::before
  margin-bottom: 4px
.v-select__selections input
  display: none
</style>
<script>
import FromNow from "@/components/common/FromNow.vue";
import VueDragResize from "vue-drag-resize";
import _ from "lodash";

export default {
  components: { VueDragResize, FromNow },
  props: {
    unitStatus: Object
  },
  data: () => ({
    width: 0,
    height: 0,
    left: 0,
    top: 0
  }),
  computed: {
    statuses() {
      return this.$store.getters["status/status"];
    },
    topFromStore() {
      let p = this.$store.getters["localStorage/situationCardPositions"];
      let top = _.find(p, x => x.unitId === this.unitStatus.id);
      if (!top) {
        return 0;
      }
      return top.top;
    },
    leftFromStore() {
      let p = this.$store.getters["localStorage/situationCardPositions"];
      let top = _.find(p, x => x.unitId === this.unitStatus.id);
      if (!top) {
        return 0;
      }
      return top.left;
    }
  },
  methods: {
    async updateStatus(unit) {
      let me = this;
      this.$debug("status", unit);
      try {
        await me.$store.dispatch("units/changeStatus", unit);
        me.$toast({
          type: "success",
          message:
            `Yksikön '` +
            unit.name +
            `' tilaksi on muutettu '` +
            unit.status.name +
            `'`
        });
      } catch (err) {
        this.$error(err);
      }
    },
    onDragStop(newRect) {
      this.$debug("newRect", newRect);
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;

      let cardPos = {
        unitId: this.unitStatus.id,
        left: this.left,
        top: this.top
      };
      this.$store.dispatch("localStorage/save", cardPos);
    }
  }
};
</script>
