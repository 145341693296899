<template>
  <div>
    <v-dialog v-model="show" max-width="400" absolute persistent>
      <v-card>
        <v-card-text class="pt-4 body-1 text-center">
          <v-row justify-center>
            <v-col cols="12">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Yhdistetään palveluun...
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//  import _ from 'lodash'

export default {
  name: "AppLoading",
  props: {
    show: Boolean
  }
};
</script>
