<template>
  <v-card class="elevation-5">
    <UnitEditor :active="editDialog" @closed="editDialog = false" />
    <v-data-table
      :items="units"
      :headers="headers"
      class="units-table"
      item-key="id"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="primary--text subtitle-2 text-uppercase"
            >Yksiköt</v-toolbar-title
          >
          <v-spacer />
          <UnitCreator />
        </v-toolbar>
      </template>

      <template v-slot:item.type="{ item }">
        {{ itemTypeDescription(item.type) }}
      </template>
      <template v-slot:item.identifier="{ item }">
        {{ item.type === 1 ? item.identifier : "" }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editUnit(item.id)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteUnit(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass">
.status-select
  font-size: 14px
.status-select.v-text-field>.v-input__control>.v-input__slot:before
  border-style: none
.status-select.v-text-field>.v-input__control>.v-input__slot:after
  border-style: none
.v-select__selections input
  display: none
</style>

<script>
// TODO: Chechk functionality and styling with other cases when we get those
import UnitCreator from "@/views/UnitCreator.vue";
import UnitEditor from "@/views/UnitEditor.vue";

export default {
  props: {
    isLoading: Boolean
  },
  components: {
    UnitCreator,
    UnitEditor
  },
  data: () => ({
    selectedStatus: null,
    editDialog: false,
    headers: [
      { text: "Tunnus", value: "name", sortable: false },
      { text: "Kuvaus", value: "description", sortable: false },
      { text: "Tyyppi", value: "type", sortable: false },
      { text: "Tilaajanumero", value: "identifier", sortable: false },
      { text: "", value: "actions", sortable: false, filterable: false }
    ]
  }),
  computed: {
    units() {
      return this.$store.getters["units/units"];
    },
    statuses() {
      return this.$store.getters["status/status"];
    }
  },
  methods: {
    itemTypeDescription(itemType) {
      switch (itemType) {
        case 1:
          return "Virve";
        case 2:
          return "Virtuaalinen";
      }
    },
    async statusChanged(unit) {
      let me = this;
      try {
        await this.$confirm(
          "Vaihdetaanko yksikön " +
            unit.name +
            " tilaksi " +
            unit.status.name +
            "?",
          "Vahvista tilamuutos"
        );
        await me.$store.dispatch("units/changeStatus", unit);
        me.$toast({
          type: "success",
          message:
            `Yksikön ` + unit.name + ` tilaksi muutettu ` + unit.status.name
        });
      } catch (err) {
        this.$error(err);
      }
    },
    async editUnit(id) {
      let me = this;
      me.$debug("editUnit", id);
      await me.$store.dispatch("units/loadUnitToEditor", id);
      me.editDialog = true;
    },
    async deleteUnit(id) {
      let me = this;
      try {
        let unit = me.units.find(u => u.id === id);
        await me.$confirm(
          "Haluatko varmasti poistaa yksikön " + unit.name + " ?",
          "Vahvista poisto"
        );
        await me.$store.dispatch("units/deleteUnit", id);
        me.$toast({
          type: "success",
          message: `Yksikkö ` + unit.name + ` poistettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    }
  }
};
</script>
