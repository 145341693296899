<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="$auth.isAuthenticated"
      v-model="drawer"
      app
      clipped
    >
      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.text"
          link
          :to="item.target"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-title>Kirjaudu ulos</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="$auth.isAuthenticated"
      app
      clipped-left
      color="primary"
      dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="mr-12 align-center">
        <img
          class="krista-small-logo"
          alt="KRISTA logo"
          src="./assets/krista_logo_neg.png"
          height="30px"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <MissionCreator inAppBar class="mr-3" />
      <NoteCreator />
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <toast></toast>
    <confirm></confirm>
    <loading :show="isLoading"></loading>
  </v-app>
</template>
<style lang="sass" scoped>
.krista-small-logo
  margin-top: 8px
</style>
<script>
import Toast from "./components/common/Toast";
import Confirm from "./components/common/Confirm";
import NoteCreator from "./views/NoteCreator.vue";
import MissionCreator from "./views/MissionCreator.vue";
import Loading from "./components/common/Loading";

export default {
  name: "App",

  components: {
    Toast,
    Confirm,
    Loading,
    NoteCreator,
    MissionCreator
  },

  props: {
    source: String
  },
  data: () => ({
    drawer: null,
    isLoading: false,
    items: [
      {
        icon: "mdi-focus-field",
        text: "Tilannekuva",
        target: "/situation"
      },
      {
        icon: "mdi-hospital-building",
        text: "Resurssitaulu",
        target: "/resource-table"
      },
      { icon: "mdi-format-list-checks", text: "Tehtävät", target: "/missions" },
      { icon: "mdi-history", text: "Aikajana", target: "/events" },
      {
        icon: "mdi-message-flash-outline",
        text: "Viestin lähetys",
        target: "/alert"
      },
      { icon: "mdi-cog", text: "Asetukset", target: "/settings" }
    ]
  }),
  methods: {
    // Log the user out
    logout() {
      let me = this;
      me.$debug("Unsubscribe for MQTT");
      //me.$mqtt.unsubsrcibe("/krista-cloud/#");
      //TODO: unsubscribe here
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  mqtt: {
    "#": function(data, topic) {
      let me = this;
      me.$debug("Message from MQTT topic", topic);
      let content = data;

      if (topic == "/krista-cloud/unit-status-update") {
        let statusUpdate = JSON.parse(content);
        this.$store.dispatch("units/updateStatus", statusUpdate);
      } else if (topic == "/krista-cloud/log-entry") {
        let event = JSON.parse(content);
        this.$store.commit("events/addReceivedEvent", event);
      } else if (topic == "/krista-cloud/mission") {
        let mission = JSON.parse(content);
        this.$store.commit("missions/addMission", mission);
      } else if (topic == "/krista-cloud/mission-delete") {
        let mission = JSON.parse(content);
        this.$store.commit("missions/deleteMission", mission.id);
      } else if (topic == "/krista-cloud/resource-change") {
        this.$store.dispatch("resources/load");
      }
    }
  },
  created() {
    this.$vuetify.theme.dark = false;
  },
  async mounted() {
    let me = this;

    console.log("KRISTA Cloud in " + process.env.NODE_ENV + " mode.");
    console.log("Info: " + process.env.VUE_APP_ACTION_INFO);

    this.$auth.$watch("loading", async loading => {
      if (loading === false && this.$auth.isAuthenticated === true) {
        console.log("Authenticated");
        me.isLoading = true;
        me.$mqtt.subscribe("/krista-cloud/#");

        await me.$store.dispatch("status/load");
        await me.$store.dispatch("units/load");
        await me.$store.dispatch("events/load");
        await me.$store.dispatch("missions/load");
        await me.$store.dispatch("resources/load");
        await me.$store.dispatch("localStorage/load");
        me.isLoading = false;
      }
    });
  }
};
</script>
