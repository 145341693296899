<template>
  <v-card class="elevation-5">
    <v-data-table
      :items="events"
      :headers="headers"
      item-key="id"
      dense
      :search="search"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat v-if="!noTitle">
          <v-toolbar-title
            class="d-flex primary--text subtitle-2 text-uppercase"
            >Aikajana</v-toolbar-title
          >
          <v-spacer />

          <v-text-field
            style="max-width: 200px"
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            hide-details
            class="ml-3"
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:item.description="{ item }">
        <v-icon v-if="isNote(item)" small class="mr-2"
          >mdi-note-text-outline</v-icon
        >
        <v-icon v-if="isStatusUpdate(item)" small class="mr-2"
          >mdi-resistor</v-icon
        >
        {{ item.description }}
      </template>

      <template v-slot:item.datetime="{ item }">
        {{ formattedDateTime(item.dateTime) }}
      </template>

      <template v-slot:header.description="{}">
        Tapahtuma
        <v-menu
          bottom
          right
          :close-on-content-click="false"
          transition="scale-transition"
          origin="top left"
        >
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">mdi-filter-menu</v-icon>
          </template>
          <v-card width="300">
            <v-list>
              <v-list-item>
                <v-checkbox
                  v-model="showNotes"
                  @change="filterUpdated()"
                  label="Näytä muistiinpanot"
                ></v-checkbox>
              </v-list-item>

              <v-list-item>
                <v-checkbox
                  v-model="showStatus"
                  @change="filterUpdated()"
                  label="Näytä tilatiedot"
                ></v-checkbox>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped></style>
<script>
// TODO: Chechk functionality and styling with other cases when we get those
import moment from "moment";
import _ from "lodash";

export default {
  props: {
    isLoading: Boolean,
    noTitle: Boolean
  },
  data: () => ({
    headers: [
      { text: "Aika", value: "datetime", sortable: false },
      { text: "Yksikkö", value: "unit", sortable: false },
      { text: "Tapahtuma", value: "description", sortable: false }
    ],
    search: ""
  }),
  computed: {
    events() {
      let me = this;
      return _.filter(me.$store.getters["events/events"], i =>
        me.isNote(i)
          ? me.showNotes
          : me.isStatusUpdate(i)
          ? me.showStatus
          : true
      );
    },
    showNotes: {
      get() {
        return this.$store.getters["localStorage/showNotes"];
      },
      set(value) {
        this.$store.commit("localStorage/set", ["showNotes", value]);
      }
    },
    showStatus: {
      get() {
        return this.$store.getters["localStorage/showStatus"];
      },
      set(value) {
        this.$store.commit("localStorage/set", ["showStatus", value]);
      }
    }
  },
  methods: {
    filterUpdated() {
      console.log("filter update");
    },
    isNote(item) {
      return item.event === "Muistiinpano";
    },
    isStatusUpdate(item) {
      return item.event === "Tilatieto muuttunut";
    },
    formattedDateTime(value) {
      return moment
        .utc(value)
        .local()
        .format("D.M. HH:mm");
    },
    formattedTime(value) {
      return moment
        .utc(value)
        .local()
        .format("HH:mm");
    }
  }
};
</script>
