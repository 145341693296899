<template>
  <v-container class="main-container px-8">
    <v-row justify="center" align="center">
      <v-col cols="10">
        <MissionListWidget
          :isLoading="isLoading"
          class="mission-list"
          canDelete
          canAdd
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass" scoped>
.mission-list
  margin-top: 10px
  margin-bottom: 10px
</style>
<script>
// @ is an alias to /src
import MissionListWidget from "@/components/MissionListWidget.vue";

export default {
  name: "Missions",
  components: {
    MissionListWidget
  },
  data: () => ({
    isLoading: false
  }),
  async mounted() {}
};
</script>
