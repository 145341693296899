import _ from "lodash";
import axios from "axios";
import * as _debug from "debug";

import { getInstance } from "../auth/index";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:ApiClient`);

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

export class ApiClient {
  static async getAuthHeader() {
    let authService = getInstance();
    let token = await authService.getTokenSilently();
    return {
      headers: {
        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
      }
    };
  }

  static async get(uri) {
    debug("GET request", uri);
    let authHeader = await ApiClient.getAuthHeader();
    const response = await client.get(uri, authHeader);
    debug("GET response", uri, response);
    return _.get(response, "data");
  }

  static async post(uri, payload = {}) {
    debug("POST request", uri, ApiClient.sanitizePayload(payload));
    let authHeader = await ApiClient.getAuthHeader();
    const response = await client.post(uri, payload, authHeader);
    debug("POST response", uri, response);
    return _.get(response, "data");
  }

  static async put(uri, payload = {}) {
    debug("PUT request", uri, ApiClient.sanitizePayload(payload));
    let authHeader = await ApiClient.getAuthHeader();
    const response = await client.put(uri, payload, authHeader);
    debug("PUT response", uri, response);
    return _.get(response, "data");
  }

  static async patch(uri, payload = {}) {
    debug("PATCH request", uri, ApiClient.sanitizePayload(payload));
    let authHeader = await ApiClient.getAuthHeader();
    const response = await client.patch(uri, payload, authHeader);
    debug("PATCH response", uri, response);
    return _.get(response, "data");
  }

  static async delete(uri) {
    debug("DELETE request", uri);
    let authHeader = await ApiClient.getAuthHeader();
    const response = await client.delete(uri, authHeader);
    debug("DELETE response", uri, response);
    return _.get(response, "data");
  }

  static sanitizePayload(payload = {}) {
    let password = _.get(payload, "password");
    if (password) {
      payload = JSON.parse(JSON.stringify(payload));
      _.set(payload, "password", "***");
    }
    return payload;
  }
}

export default ApiClient;
