<template>
  <v-card class="card-normal" margin="50,50,50,50,">
    <v-form v-model="validForm">
      <v-row>
        <v-col lg="6">
          <v-select
            v-model="unit"
            :items="units"
            item-value="identifier"
            item-text="name"
            :label="$t('Yksikkö')"
            outlined
            required
            :rules="$rules.selection"
          ></v-select>
        </v-col>
        <v-col lg="6">
          <v-select
            v-model="status"
            :items="statuses"
            item-value="value"
            item-text="name"
            :label="$t('Tila')"
            outlined
            required
            :rules="$rules.selection"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn
            justify-end
            color="primary"
            large
            :disabled="!validForm"
            @click="sendStatus"
          >
            Lähetä tilatieto
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<style lang="sass" scoped></style>
.card-normal margin-top: 100px
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  name: "Status",
  components: {},
  data: () => ({
    validForm: false,
    unit: {},
    status: {}
  }),
  computed: {
    units() {
      return this.$store.getters["units/units"];
    },
    statuses() {
      return this.$store.getters["status/status"];
    }
  },
  methods: {
    async sendStatus() {
      var cloudStatus = { From: this.unit, To: "", Status: this.status };
      this.$mqtt.publish("/status", JSON.stringify(cloudStatus));
    }
  },
  async mounted() {}
};
</script>
