<template>
  <span>
    <v-btn color="primary" small text @click="newStatus">
      <v-icon small>mdi-plus</v-icon>
      Uusi tilatieto
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="primary--text subtitle-2 text-uppercase"
            >Uusi tilatieto</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="validForm">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="description"
                    label="Nimi"
                    filled
                    hint='Esimerkki: "Matkalla"'
                    required
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Numeerinen arvo"
                    hint='Käytä Virve-verkon status-arvoa, mikäli mahdollista. Esimerkiksi "327001"'
                    v-model.number="value"
                    filled
                    required
                    :rules="$rules.number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  Taustaväri
                  <v-color-picker
                    v-model="colorBackground"
                    flat
                    show-swatches
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                  />
                </v-col>

                <v-col cols="6">
                  Tekstin väri
                  <v-color-picker
                    v-model="colorText"
                    flat
                    show-swatches
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Peruuta
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!validForm"
            @click="createStatus"
          >
            Tallenna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<style lang="sass" scoped></style>
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    dialog: false
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.status.editor.id;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.id", value]);
      }
    },
    value: {
      get() {
        return this.$store.state.status.editor.value;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.value", value]);
      }
    },
    description: {
      get() {
        return this.$store.state.status.editor.name;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.name", value]);
      }
    },
    colorText: {
      get() {
        return this.$store.state.status.editor.colorText;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.colorText", value]);
      }
    },
    colorBackground: {
      get() {
        return this.$store.state.status.editor.colorBackground;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.colorBackground", value]);
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async newStatus() {
      let me = this;
      await me.$store.commit("status/newStatusToEditor", me.$store.state);
      me.dialog = true;
    },
    async createStatus() {
      let me = this;
      try {
        let status = await me.$store.dispatch("status/createStatus");
        me.$toast({
          type: "success",
          message: `Uusi tilatieto '${status.name}' on tallennettu`
        });
        me.dialog = false;
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    }
  }
};
</script>
