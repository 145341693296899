<template>
  <v-container class="main-container px-8">
    <v-row justify="center" align="center">
      <v-col cols="10">
        <UnitStatusListWidget :isLoading="isLoading" class="unit-list" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="10">
        <StatusListWidget class="status-list" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass" scoped>
.status-list
  margin-top: 10px
  margin-bottom: 10px
</style>
<script>
// @ is an alias to /src
import StatusListWidget from "@/components/StatusListWidget.vue";
import UnitStatusListWidget from "@/components/UnitStatusListWidget.vue";

export default {
  name: "Settings",
  components: {
    StatusListWidget,
    UnitStatusListWidget
  },
  data: () => ({
    isLoading: false
  }),
  async mounted() {}
};
</script>
