<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="primary--text subtitle-2 text-uppercase"
          >Muokkaa yksikköä</span
        >
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="validForm">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Tunnus"
                  filled
                  hint='esimerkiksi "EME 1211"'
                  required
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="description"
                  label="Kuvaus"
                  filled
                  hint='esimerkiksi "Ensihoitoyksikkö, Kotka"'
                  required
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="type" label="Tyyppi" mandatory disabled>
                  <v-radio label="Virtuaaliyksikkö" :value="2"></v-radio>
                  <v-radio label="VIRVE-yksikkö" :value="1"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="type == 1"
                  v-model="identifier"
                  label="Virve-tilaajanumero"
                  placeholder="esim. 1234567"
                  outlined
                  required
                  autocomplete="off"
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Peruuta
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="saving"
          :disabled="!validForm"
          @click="saveUnit"
        >
          Tallenna
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="sass" scoped></style>
.net-unit-card padding: 100px
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean,
    active: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    validForm: false,
    saving: false
  }),
  computed: {
    dialog: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("closed", value);
      }
    },
    id: {
      get() {
        return this.$store.state.units.editor.id;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.id", value]);
      }
    },
    name: {
      get() {
        return this.$store.state.units.editor.name;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.name", value]);
      }
    },
    description: {
      get() {
        return this.$store.state.units.editor.description;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.description", value]);
      }
    },
    type: {
      get() {
        return this.$store.state.units.editor.type;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.type", value]);
      }
    },
    identifier: {
      get() {
        console.log("editor get " + this.$store.state.units.editor.type);
        return this.$store.state.units.editor.identifier;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.identifier", value]);
      }
    },
    original() {
      return this.$store.state.units.units[this.missionId];
    },
    hasChanges() {
      return this.$store.getters["units/editorHasChanges"];
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async saveUnit() {
      let me = this;
      try {
        me.saving = true;
        await me.$store.dispatch("units/saveUnitInEditor");
        await me.$store.dispatch("units/load");
        me.saving = false;
        me.dialog = false;
        me.$toast({
          type: "success",
          message: `Muutokset yksikköön '${me.name}' on tallennettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.saving = false;
      }
    }
  }
};
</script>
