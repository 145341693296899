import _ from "lodash";
import Vue from "vue";
import { sureClone } from "../../lib/DataUtils";

export default ({ state = {}, getters = {}, mutations = {}, actions = {} }) => {
  return {
    namespaced: true,
    getters,
    actions,
    state: _.merge(state, {
      _initial: sureClone(state)
    }),
    mutations: _.merge(mutations, {
      set(state, [key, value]) {
        if (key.match(/\.|\[/)) {
          let parentPath = key
            .split(".")
            .slice(0, -1)
            .join(".");
          let childKey = key.split(".").pop();
          Vue.set(_.get(state, parentPath), childKey, value);
        } else {
          Vue.set(state, key, value);
        }
      },

      unset(state, key) {
        if (key.match(/\.|\[/)) {
          let parentPath = key
            .split(".")
            .slice(0, -1)
            .join(".");
          let childKey = key.split(".").pop();
          Vue.delete(_.get(state, parentPath), childKey);
        } else {
          Vue.delete(state, key);
        }
      },

      empty(state, key) {
        if (key.match(/\.|\[/)) {
          let parentPath = key
            .split(".")
            .slice(0, -1)
            .join(".");
          let childKey = key.split(".").pop();
          Vue.set(_.get(state, parentPath), childKey, null);
        } else {
          state[key] = null;
        }
      },

      mapSet(state, [path, id, payload]) {
        Vue.set(_.get(state, path), id, payload);
      },

      mapUnset(state, [path, id]) {
        Vue.delete(_.get(state, path), id);
      },

      reset_default_state(state) {
        let cleanState = sureClone(state._initial);
        _.each(cleanState, (value, key) => {
          Vue.set(state, key, value);
        });
      }
    })
  };
};
