import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:SituationStore`);

export default ModuleBase({
  state: {
    unitsStatuses: {}
  },

  getters: {
    unitStatuses({ unitStatuses }) {
      let values = _.values(unitStatuses);
      return values;
    }
  },

  mutations: {
    clearUnitStatuses(state) {
      state.unitStatuses = [];
    }
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let unitStatuses = await ApiClient.get(`/units/unitstatuses`);
      debug("load", unitStatuses);
      commit("set", ["unitStatuses", _.keyBy(unitStatuses, "id")]);
    }
  }
});
