import _ from "lodash";
import * as _debug from "debug";
import Vue from "vue";
import ApiClient from "@/lib/ApiClient";
import { objectDiff, sureClone } from "@/lib/DataUtils";

import ModuleBase from "../common/ModuleBase";
import moment from "moment";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:MissionsStore`);

const DEFAULT_EDITOR = {
  id: null,
  time: null,
  code: null,
  municipality: null,
  address: null,
  details: null,
  units: null,
  state: 0
};

export default ModuleBase({
  state: {
    missions: {},
    editor: sureClone(DEFAULT_EDITOR)
  },

  getters: {
    missions({ missions }) {
      let values = _.orderBy(_.values(missions), ["time"], ["desc"]);
      return values;
    },
    editorChanges(state) {
      const missionId = _.get(state, "editor.id");
      if (!missionId) {
        return null;
      }
      const original = _.get(state.missions, missionId);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    }
  },

  mutations: {
    clearMissions(state) {
      state.missions = [];
    },
    newMissionToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addMission(state, mission) {
      Vue.set(state.missions, mission.id, mission);
    },
    deleteMission(state, missionId) {
      Vue.delete(state.missions, missionId);
    }
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let missions = await ApiClient.get(`/missions`);
      debug("load", missions);
      commit("set", ["missions", _.keyBy(missions, "id")]);
    },
    async loadMissionToEditor({ state, commit }, missionId) {
      debug("loadMissionToEditor", missionId);
      const mission = _.get(state.missions, missionId);
      debug("loadMissionToEditor", mission);
      if (!mission) {
        throw new Error("Mission not found");
      }
      let toEditor = sureClone(mission);
      _.set(
        toEditor,
        "units",
        toEditor.units.map(u => u.id)
      );
      commit("set", ["editor", toEditor]);
    },
    async saveMissionInEditor({ state, getters /*commit*/ }) {
      let missionId = state.editor.id;
      if (!getters.editorHasChanges) {
        throw new Error("No changes to save");
      }
      let modifiedMission = sureClone(state.editor);
      debug("saveMissionInEditor", missionId, modifiedMission);
      let result = await ApiClient.put(
        `/missions/${missionId}`,
        modifiedMission
      );
      debug("saveMissionInEditor, result", result);
    },
    async createMission({ state, commit }) {
      let mission = sureClone(state.editor);
      _.unset(mission, "id");
      _.set(mission, "time", moment());
      debug("createMission", mission);
      let response = await ApiClient.post("/missions", mission);
      debug("createMission - response", response);
      commit("addMission", response);
      return response;
    },
    async deleteMission({ commit }, missionId) {
      debug("deleteMission", missionId);
      let result = await ApiClient.delete(`/missions/${missionId}`);
      debug("deleteMission - result", result);
      commit("deleteMission", missionId);
    }
  }
});
