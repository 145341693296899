import _ from "lodash";

export function objectDiff(o1, o2) {
  return Object.keys(o2).reduce((diff, key) => {
    if (_.isEqual(o1[key], o2[key])) return diff;
    return {
      ...diff,
      [key]: o2[key]
    };
  }, {});
}

export function mapDiff(base, object) {
  function removals(base, object) {
    return _.filter(base, function(x) {
      return !_.has(object, x.id);
    });
  }

  let _changes = objectDiff(base, object);
  let _removals = removals(base, object);

  let diff = {};
  if (!_.isEmpty(_changes)) diff.changes = _changes;
  if (!_.isEmpty(_removals)) diff.removals = _removals;
  return diff;
}

export function arrayDiff(base, object) {
  return base
    .filter(x => !object.includes(x))
    .concat(object.filter(x => !base.includes(x)));
}

export function sureClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function ensureHttpsUrl(url) {
  if (url == null) return null;
  return url.replace("http:", "https:");
}
