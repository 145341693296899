<template>
  <span>
    <v-btn color="primary" small text @click="newUnit">
      <v-icon small>mdi-plus</v-icon>
      Uusi yksikkö
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="primary--text subtitle-2 text-uppercase"
            >Uusi yksikkö</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="validForm">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Tunnus"
                    filled
                    hint='esimerkiksi "EME 1211"'
                    required
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="description"
                    label="Kuvaus"
                    filled
                    hint='esimerkiksi "Ensihoitoyksikkö, Kotka"'
                    required
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="type" label="Tyyppi">
                    <v-radio label="Virtuaaliyksikkö" :value="2"></v-radio>
                    <v-radio label="VIRVE-yksikkö" :value="1"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="type == 1"
                    v-model="identifier"
                    label="Virve-tilaajanumero"
                    placeholder="esim. 1234567"
                    outlined
                    required
                    autocomplete="off"
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Peruuta
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!validForm"
            @click="createUnit"
          >
            Tallenna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<style lang="sass" scoped></style>
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    dialog: false
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.units.editor.id;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.id", value]);
      }
    },
    name: {
      get() {
        return this.$store.state.units.editor.name;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.name", value]);
      }
    },
    description: {
      get() {
        return this.$store.state.units.editor.description;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.description", value]);
      }
    },
    type: {
      get() {
        console.log("creator get " + this.$store.state.units.editor.type);
        return this.$store.state.units.editor.type;
      },
      set(value) {
        console.log("creator set " + value);
        this.$store.commit("units/set", ["editor.type", value]);
      }
    },
    identifier: {
      get() {
        return this.$store.state.units.editor.identifier;
      },
      set(value) {
        this.$store.commit("units/set", ["editor.identifier", value]);
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async newUnit() {
      let me = this;
      await me.$store.commit("units/newUnitToEditor", me.$store.state);
      me.dialog = true;
    },
    async createUnit() {
      let me = this;
      try {
        let unit = await me.$store.dispatch("units/createUnit");
        me.$toast({
          type: "success",
          message: `Uusi yksikkö '${unit.name} - ${unit.description}' on tallennettu`
        });
        me.dialog = false;
        await me.$store.dispatch("units/load");
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    }
  }
};
</script>
