<template>
  <span>
    <v-btn v-if="inAppBar" color="white" small outlined @click="newMission">
      <v-icon small left>mdi-bell-plus</v-icon>
      Uusi tehtävä
    </v-btn>

    <v-btn v-else color="primary" small text @click="newMission">
      <v-icon small>mdi-plus</v-icon>
      Uusi tehtävä
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="primary--text subtitle-2 text-uppercase"
            >Uusi tehtävä</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="validForm">
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Yksiköt"
                    v-model="units"
                    multiple
                    :items="availableUnits"
                    item-text="name"
                    item-value="id"
                    placeholder="Valitse yksiköt"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="code"
                    label="Tehtävä"
                    placeholder="esim. 704A"
                    outlined
                    required
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="municipality"
                    label="Kunta"
                    placeholder="esim. Kotka"
                    outlined
                    required
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="address"
                    label="Osoite"
                    placeholder="esim. Hätäkuja 112"
                    outlined
                    required
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="details"
                    label="Lisätiedot"
                    placeholder="esim. Ovikoodi 1234"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Peruuta
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!validForm"
            :loading="isLoading"
            @click="createMission"
          >
            Tallenna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<style lang="sass" scoped></style>
.net-unit-card padding: 100px
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean,
    inAppBar: Boolean
  },
  data: () => ({
    validForm: false,
    dialog: false,
    isLoading: false
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.missions.editor.id;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.id", value]);
      }
    },
    time: {
      get() {
        return this.$store.state.missions.editor.time;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.time", value]);
      }
    },
    code: {
      get() {
        return this.$store.state.missions.editor.code;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.code", value]);
      }
    },
    municipality: {
      get() {
        return this.$store.state.missions.editor.municipality;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.municipality", value]);
      }
    },
    address: {
      get() {
        return this.$store.state.missions.editor.address;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.address", value]);
      }
    },
    details: {
      get() {
        return this.$store.state.missions.editor.details;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.details", value]);
      }
    },
    units: {
      get() {
        return this.$store.state.missions.editor.units;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.units", value]);
      }
    },
    availableUnits: {
      get() {
        return this.$store.getters["units/units"];
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async newMission() {
      let me = this;
      await me.$store.commit("missions/newMissionToEditor", me.$store.state);
      me.dialog = true;
    },
    async createMission() {
      let me = this;
      try {
        me.isLoading = true;
        let mission = await me.$store.dispatch("missions/createMission");
        me.$toast({
          type: "success",
          message: `Uusi tehtävä '${mission.code} - ${mission.address}' on lisätty`
        });
        me.isLoading = false;
        me.dialog = false;
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.isLoading = false;
      }
    }
  }
};
</script>
