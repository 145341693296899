import _ from "lodash";
import * as _debug from "debug";

//import ApiClient from "@/lib/ApiClient";
//import { sureClone } from "@/lib/DataUtils";

import ModuleBase from "../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:MqttStore`);

export default ModuleBase({
  state: {
    status: {}
    //editor: sureClone(DEFAULT_EDITOR)
  },

  getters: {
    status({ status }) {
      let values = _.values(status);
      return values;
    }
  },

  mutations: {},
  actions: {
    async connect() {
      debug("connect");
      //let status = await ApiClient.get(`/status`);
      //debug("load", status);
      //commit("set", ["status", _.keyBy(status, "id")]);
    },
    async sendStatus() {
      debug("send status");
    }
  },
  mqtt: {}
});
