<template>
  <v-container fill-height class="d-flex justify-center flex-column">
    <img
      class="krista-logo"
      alt="KRISTA logo"
      src="../assets/krista_logo_pos.png"
    />
    <v-btn
      v-if="!$auth.isAuthenticated && !$auth.loading"
      color="primary"
      large
      @click="login"
    >
      Kirjaudu palveluun
    </v-btn>
  </v-container>
</template>
<style lang="sass" scoped>
.krista-logo
  margin-bottom: 50px
</style>
<script>
export default {
  name: "Home",
  components: {},
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    }
  }
};
</script>
