import validator from "validator";
export default {
  computed: {
    $rules: () => ({
      required: [v => (!!v && v.length >= 0) || "Pakollinen tieto"],
      selection: [v => !!v || "Pakollinen valinta"],
      atLeastOneSelection: [v => (!!v && v.length > 0) || "Valinta puuttuu"],
      email: [
        v => (!!v && validator.isEmail(v)) || "Virheellinen sähköpostiosoite"
      ],
      password: [v => (!!v && v.length >= 8) || "Salasana on liian lyhyt"],
      number: [v => !!v || "Pakollinen tieto"]
    })
  }
};
