import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";
import { sureClone } from "@/lib/DataUtils";

import ModuleBase from "../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:ResourcesStore`);

const DEFAULT_EDITOR = {
  id: null,
  name: null,
  state: "DISABLED"
};

export default ModuleBase({
  state: {
    resources: [],
    editor: sureClone(DEFAULT_EDITOR)
  },
  getters: {
    resources({ resources }) {
      return resources;
    }
  },
  mutations: {},
  actions: {
    async load({ commit }) {
      debug("load");
      let resources = await ApiClient.get(`/resourcetable `);
      debug("load", resources);
      commit("set", ["resources", resources]);
    },
    async changeState(_, { resourceId, state }) {
      debug("changeState", resourceId);
      let result = await ApiClient.post(`/resources/${resourceId}/state`, {
        state
      });
      debug("changeState - result", result);
    }
  }
});
