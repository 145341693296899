<template>
  <span>
    <v-btn color="white" small outlined @click="newNote">
      <v-icon small left>mdi-pencil-plus</v-icon>
      Uusi muistiinpano
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="primary--text subtitle-2 text-uppercase"
            >Uusi muistiinpano</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="validForm">
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Yksiköt (valinnainen)"
                    v-model="units"
                    multiple
                    :items="availableUnits"
                    item-text="name"
                    item-value="name"
                    placeholder="Valitse yksiköt"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="text"
                    label="Teksti"
                    placeholder="Kirjoita muistiinpano tähän..."
                    outlined
                    required
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialog = false">
            Peruuta
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!validForm"
            @click="createNote"
            :loading="isLoading"
          >
            Tallenna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<style lang="sass" scoped></style>
.net-unit-card padding: 100px
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    dialog: false,
    isLoading: false
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.events.editor.id;
      },
      set(value) {
        this.$store.commit("events/set", ["editor.id", value]);
      }
    },
    text: {
      get() {
        return this.$store.state.events.editor.description;
      },
      set(value) {
        this.$store.commit("events/set", ["editor.description", value]);
      }
    },
    units: {
      get() {
        return this.$store.state.events.editor.unit;
      },
      set(value) {
        this.$store.commit("events/set", ["editor.unit", value]);
      }
    },
    availableUnits: {
      get() {
        return this.$store.getters["units/units"];
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async newNote() {
      let me = this;
      await me.$store.commit("events/newEventToEditor", me.$store.state);
      me.dialog = true;
    },
    async createNote() {
      let me = this;
      try {
        me.isLoading = true;
        await me.$store.dispatch("events/createEvent");
        me.$toast({
          type: "success",
          message: `Muistiinpano on tallennettu`
        });
        me.dialog = false;
        me.isLoading = false;
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.isLoading = false;
      }
    }
  }
};
</script>
