<template>
  <v-card class="elevation-5">
    <MissionEditor
      v-if="canDelete"
      :active="editDialog"
      @closed="editDialog = false"
    />
    <v-data-table
      :items="missions"
      :headers="headers"
      hide-default-footer
      class="missions-table"
      item-key="id"
      disable-pagination
      dense
      :search="search"
      :custom-filter="filter"
      :loading="isLoading"
      loading-text="Tietoja haetaan..."
    >
      <template v-slot:top>
        <v-toolbar flat v-if="!noTitle">
          <v-toolbar-title class="primary--text subtitle-2 text-uppercase"
            >Tehtävät</v-toolbar-title
          >
          <v-spacer />
          <v-text-field
            style="max-width: 200px"
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer v-if="canAdd" />
          <MissionCreator v-if="canAdd" />
        </v-toolbar>
      </template>

      <template v-slot:item.units="{ item }">
        {{ unitList(item.units) }}
      </template>

      <template v-slot:item.time="{ item }">
        {{ formattedDateTime(item.time) }}
      </template>

      <template v-slot:item.details="{ item }">
        {{
          item.details && 0 !== item.details.length
            ? item.details
            : "Ei lisätietoja"
        }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="canDelete"
          small
          class="mr-2"
          @click="editMission(item.id)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="canDelete" small @click="deleteMission(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped></style>
<script>
// TODO: Chechk functionality and styling with other cases when we get those
import moment from "moment";
import MissionCreator from "@/views/MissionCreator.vue";
import MissionEditor from "@/views/MissionEditor.vue";

export default {
  props: {
    isLoading: Boolean,
    canDelete: Boolean,
    canAdd: Boolean,
    dataUpdated: Function,
    noTitle: Boolean
  },
  components: {
    MissionCreator,
    MissionEditor
  },
  data: () => ({
    editDialog: false,
    search: "",
    headers: [
      { text: "Yksiköt", value: "units", sortable: false },
      { text: "Aika", value: "time", sortable: false, filterable: false },
      { text: "Tehtävä", value: "code", sortable: false, filterable: false },
      {
        text: "Paikkakunta",
        value: "municipality",
        sortable: false,
        filterable: false
      },
      { text: "Osoite", value: "address", sortable: false, filterable: false },
      {
        text: "Lisätietoja",
        value: "details",
        sortable: false,
        filterable: false
      },
      { text: "", value: "actions", sortable: false, filterable: false }
    ]
  }),
  computed: {
    missions() {
      let me = this;
      return me.$store.getters["missions/missions"];
    }
  },
  methods: {
    filter(value, search, item) {
      if (search == null) return true;
      var unitString = item.units.map(u => u.name).join(", ");
      var unitMatch =
        unitString.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !==
        -1;
      var addressMatch =
        item.address.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !==
        -1;
      var codeMatch =
        item.code.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !==
        -1;
      var municipalityMatch =
        item.municipality
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1;
      var detailsMatch =
        item.details.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !==
        -1;

      return (
        value != null &&
        (unitMatch ||
          addressMatch ||
          codeMatch ||
          municipalityMatch ||
          detailsMatch)
      );
    },
    formattedDateTime(value) {
      return moment
        .utc(value)
        .local()
        .format("D.M. HH:mm");
    },
    unitList(units) {
      let listString = units.map(u => u.name).join(", ");
      return listString ? listString : "Ei yksiköitä";
    },
    async editMission(id) {
      let me = this;
      me.$debug("editMission", id);
      await me.$store.dispatch("missions/loadMissionToEditor", id);
      me.editDialog = true;
    },
    async deleteMission(id) {
      let me = this;
      try {
        let mission = me.missions.find(m => m.id === id);
        await me.$confirm(
          "Haluatko varmasti poistaa tehtävän " +
            mission.code +
            " - " +
            mission.address +
            ", " +
            mission.municipality +
            " ?",
          "Vahvista poisto"
        );
        await me.$store.dispatch("missions/deleteMission", id);
        me.$toast({
          type: "success",
          message:
            `Tehtävä '` +
            mission.code +
            " - " +
            mission.address +
            ", " +
            mission.municipality +
            `' on poistettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    missions: function(newMissions, oldMissions) {
      this.$debug(newMissions, oldMissions);
      if (newMissions.length > oldMissions.length) {
        this.$debug("uusi tuli");
        if (this.dataUpdated) {
          this.dataUpdated();
        }
      }
    }
  }
};
</script>
