<template>
  <v-container class="main-container px-0">
        <ResourceTableWidget :isLoading="isLoading" class="resource-table" />
  </v-container>
</template>
<style lang="sass" scoped>
.resource-table
  margin-top: 10px
  margin-bottom: 10px
</style>
<script>
// @ is an alias to /src
import ResourceTableWidget from "@/components/ResourceTableWidget.vue";

export default {
  name: "ResourceTable",
  components: {
    ResourceTableWidget
  },
  data: () => ({
    isLoading: false
  }),
  async mounted() {}
};
</script>
