import _ from "lodash";
import * as _debug from "debug";
import Vue from "vue";
import ApiClient from "@/lib/ApiClient";
import { objectDiff, sureClone } from "@/lib/DataUtils";

import ModuleBase from "../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:StatusStore`);

const DEFAULT_EDITOR = {
  id: null,
  name: null,
  value: null,
  systemStatus: false,
  colorText: "#000000",
  colorBackground: "#F0F0F0"
};

export default ModuleBase({
  state: {
    status: {},
    editor: sureClone(DEFAULT_EDITOR)
  },

  getters: {
    status({ status }) {
      let values = _.values(status);
      return values;
    },
    editorChanges(state) {
      const statusId = _.get(state, "editor.id");
      if (!statusId) {
        return null;
      }
      const original = _.get(state.status, statusId);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    }
  },

  mutations: {
    clearStatus(state) {
      state.status = [];
    },
    newStatusToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addStatus(state, status) {
      Vue.set(state.status, status.id, status);
    },
    deleteStatus(state, statusId) {
      Vue.delete(state.status, statusId);
    }
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let status = await ApiClient.get(`/status`);
      debug("load", status);
      commit("set", ["status", _.keyBy(status, "id")]);
    },
    async loadStatusToEditor({ state, commit }, statusId) {
      debug("loadStatusToEditor", statusId);
      const status = _.get(state.status, statusId);
      if (!status) {
        throw new Error("Status not found");
      }
      commit("set", ["editor", sureClone(status)]);
    },
    async saveStatusInEditor({ state, getters /*commit*/ }) {
      let statusId = state.editor.id;
      if (!getters.editorHasChanges) {
        throw new Error("No changes to save");
      }
      let modifiedStatus = sureClone(state.editor);
      debug("saveStatusInEditor", statusId, modifiedStatus);
      let result = await ApiClient.put(`/status/${statusId}`, modifiedStatus);
      debug("saveStatusInEditor, result", result);
    },
    async createStatus({ state, commit }) {
      let status = sureClone(state.editor);
      _.unset(status, "id");
      debug("createStatus", status);
      let response = await ApiClient.post("/status", status);
      debug("createStatus - response", response);
      commit("addStatus", response);
      return response;
    },
    async deleteStatus({ commit }, statusId) {
      debug("deleteStatus", statusId);
      let result = await ApiClient.delete(`/status/${statusId}`);
      debug("deleteStatus - result", result);
      commit("deleteStatus", statusId);
    }
  }
});
