<template>
  <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
      <v-sheet v-bind="attrs" v-on="on" class="resource-sheet" :color="getColorForResource(resource)" rounded elevation="3" height="100" width="100" style="margin-right: 10px; margin-bottom: 30px;">
        <div class="resource-title">{{ resource.name }}</div>
        <div class="resource-state">
          {{ getStateText(resource.state) }}
          
            
        </div>
        <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="white"
          size="20"
        ></v-progress-circular>
      </div>
      </v-sheet>
  </template>
        <v-list>
          <v-list-item v-for="(state, index) in states" :key="index" @click="menuActionClick(resource.id, state.value)">
            <v-list-item-title>{{ state.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
</template>
<style lang="sass">
.area-title
  font-weight: 700
.group-title
  font-weight: 600
.resource-sheet
  margin: 60
  padding: 10px
.resource-title
  font-weight: 700
  text-align: center
  font-size: large
  color: white
  margin-bottom: 15px
.resource-state
  text-align: center
  font-size: small
  color: white
.resource-body
</style>

<script>
// TODO: Chechk functionality and styling with other cases when we get those

export default {
  props: { resource: Object },
  components: {},
  data: () => ({
    states: [
      { name: "Vapaa", value: "FREE" },
      { name: "Varattu", value: "OCCUPIED" },
      { name: "Ei käytössä", value: "DISABLED" }
    ],
    loading: false
  }),
  computed: {
    resources: {
      get() {
        let me = this;
        return me.$store.getters["resources/resources"];
      }
    }
  },
  methods: {
    getStateText(state) {
      switch (state) {
        case "FREE":
          return "VAPAA";
        case "OCCUPIED":
          return "VARATTU";
        case "DISABLED":
          return "EI KÄYTÖSSÄ";
        default:
          return "?";
      }
    },
    getColorForResource(resource) {
      if (resource == null || resource.state == null) {
        return "grey";
      }
      switch (resource.state) {
        case "FREE":
          return "green";
        case "OCCUPIED":
          return "red";
        case "DISABLED":
          return "grey";
        default:
          return "grey";
      }
    },
    async menuActionClick(resource, state) {
      let me = this;
      try {
        me.loading = true;
        await me.$store.dispatch("resources/changeState", {
          resourceId: resource,
          state: state
        });
        me.loading = false;
      } catch (err) {
        this.$error(err);
        me.loading = false;
      }
    }
  },
  watch: {}
};
</script>
